import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { mask } from 'remask';
//
import { validaCpf } from '../../functions/validaCpf';
import { validaCnpj } from '../../functions/validaCnpj';
//
import apiPlay from '../../services/apiPlay';
//
import Input from '../Input';
import Botao from '../Botao';
import Row from '../Row';
//
import HipercardImg from '../../assets/bandeira_hipercard.png';
import DiscoverImg from '../../assets/bandeira_discover.png';
import DinnersImg from '../../assets/bandeira_dinners.png';
import MasterImg from '../../assets/bandeira_master.png';
import SslImg from '../../assets/site_seguro_imagem.png';
import VaziaImg from '../../assets/bandeira_vazia.png';
import AmexImg from '../../assets/bandeira_amex.png';
import VisaImg from '../../assets/bandeira_visa.png';
import EloImg from '../../assets/bandeira_elo.png';
//
import styles from './TelaCredito.module.css';

export default function TelaCredito({ fatura, payId }) {

  //STATES
  const [loadingPag, setLoadingPag] = useState(false);

  const [numCartao, setNumCartao] = useState('');
  const [nomeCartao, setNomeCartao] = useState('');
  const [mesVencimento, setmesVencimento] = useState('');
  const [anoVencimento, setAnoVencimento] = useState('');
  const [cvv, setCvv] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');

  const anoAtual = new Date().getFullYear().toString();
  
  //FUNCTION

  function realizaPagamento(e) {
    e.preventDefault();
    setLoadingPag(true);

    let dados = {
      payid: payId,
      pagacartao: '1',
      nomeCartao: nomeCartao,
      numero: numCartao,
      expirames: mesVencimento,
      expiraano: '20'+anoVencimento,
      ccv: cvv,
      nome:'',
      email:'',
      cpfCnpj: cpfCnpj,
      postalCode: '',
      endereconumero:'',
      enderecocomplemento:'',
      telefone:'',
      telefonecelular:'',
    }

    apiPlay.post('/api/asaasfatura', dados)
    .then(response => {

      if(response.data.status === 'CONFIRMED'){
        toast.success('Pagamento Efetuado! Em alguns minutos sua linha será recarregada.');
        setLoadingPag(false);

        setTimeout(() => {
          window.location.reload();
        }, 5000);
        
        return;
      }

      if(response.data.status !== 'CONFIRMED'){
        console.log(response.data);
        toast.error('Erro ao efetuar pagamento.');
        setLoadingPag(false);
        return;
      }

    })

  }

  //BODY
  return (
    <section className={styles['container']}>

      <span className={styles['title']}>
        Informe os dados do cartão para finalizar o pagamento
      </span>

      <div className={styles['form-container']}>

        <form className={styles['form']} onSubmit={realizaPagamento}>

          <Row style={{ flexWrap: 'wrap', justifyContent: 'center', margin: '0.5rem' }}>
            <img
              className={styles['bandeiras']}
              src={MasterImg}
              alt='bandeira-master'
            />
            <img
              className={styles['bandeiras']}
              src={VisaImg}
              alt='bandeira-visa'
            />
            <img
              className={styles['bandeiras']}
              src={AmexImg}
              alt='bandeira-amex'
            />
            <img
              className={styles['bandeiras']}
              src={EloImg}
              alt='bandeira-elo'
            />
            <img
              className={styles['bandeiras']}
              src={DinnersImg}
              alt='bandeira-dinners'
            />
            <img
              className={styles['bandeiras']}
              src={DiscoverImg}
              alt='bandeira-discover'
            />
            <img
              className={styles['bandeiras']}
              src={HipercardImg}
              alt='bandeira-hipercard'
            />
          </Row>

          <Row style={{ position: 'relative' }}>
            <Input
              value={mask(numCartao, ['9999 9999 9999 9999'])}
              onChange={(e) => { setNumCartao(mask(e.target.value, ['9999999999999999'])) }}
              label='Número do cartão'
              required
            />
            <img
              className={styles['bandeira-img']}
              src={VaziaImg}
              alt='bandeira-vazia'
            />
            {numCartao.startsWith('5') &&
              <img
                className={styles['bandeira-img']}
                src={MasterImg}
                alt='bandeira-master'
              />
            }
            {numCartao.startsWith('4') &&
              <img
                className={styles['bandeira-img']}
                src={VisaImg}
                alt='bandeira-visa'
              />
            }
            {(numCartao.startsWith('34') || numCartao.startsWith('37')) &&
              <img
                className={styles['bandeira-img']}
                src={AmexImg}
                alt='bandeira-amex'
              />
            }
            {(numCartao.startsWith('36') || numCartao.startsWith('38')
              || numCartao.startsWith('301') || numCartao.startsWith('305')
            ) &&
              <img
                className={styles['bandeira-img']}
                src={EloImg}
                alt='bandeira-elo'
              />
            }
            {(numCartao.startsWith('60') || numCartao.startsWith('3841')) &&
              <img
                className={styles['bandeira-img']}
                src={HipercardImg}
                alt='bandeira-hipercard'
              />
            }
            {(numCartao.startsWith('6011') || numCartao.startsWith('65')) &&
              <img
                className={styles['bandeira-img']}
                src={DiscoverImg}
                alt='bandeira-discover'
              />
            }
          </Row>

          <Row>
            <Input
              value={nomeCartao}
              onChange={(e) => { setNomeCartao(e.target.value.toUpperCase()) }}
              label='Nome do Titular (Igual no Cartão)'
              required
            />
          </Row>

          <Row>
            <Input
              value={mesVencimento}
              onChange={(e) => { setmesVencimento(mask(e.target.value, ['99'])) }}
              label='Mês'
              required
            />

            <Input
              value={anoVencimento}
              onChange={(e) => { setAnoVencimento(mask(e.target.value, ['99'])) }}
              label='Ano'
              required
            />
          </Row>

          <Row>
            <Input
              value={cvv}
              onChange={(e) => { setCvv(mask(e.target.value, ['9999'])) }}
              label='CVV (Código de Segurança)'
              required
            />
          </Row>

          <Row>
            <Input
              value={mask(cpfCnpj, ['999.999.999-99', '99.999.999/9999-99'])}
              onChange={(e) => { setCpfCnpj(mask(e.target.value, ['99999999999', '99999999999999'])) }}
              label='Cpf ou Cnpj do titular do cartão'
              required
            />
          </Row>

          <Row>
          <Botao
              type='submit'
              text='Confirmar'
              style={{ width: '100%' }}
              loading={loadingPag}
              disabled={
                mesVencimento > '12' ||
                mesVencimento <= '0' ||
                anoVencimento < anoAtual ||
                (cpfCnpj.length > 11 && !validaCnpj(cpfCnpj || '00000000000000')) ||
                (cpfCnpj.length <= 11 && !validaCpf(cpfCnpj || '00000000000'))
              }
            />
          </Row>

        </form>

        <div className={styles['ssl']}>
          <img src={SslImg} alt='site-seguro' />
        </div>

      </div>

    </section>
  )
}
